<template>
  <div class="settings-content-wrapper">
    <breadcrumbs :breadcrumbs="breadcrumbs"></breadcrumbs>
    <h1>Tax Rates</h1>
    <p>Configure a group of tax rates for locations you provide services in.</p>
    <hr>
    <div v-if="taxRateGroups">
      <div class="box bunya-box card" v-for="(taxRateGroup, index) in taxRateGroups" v-bind:key="index">
        <router-link :to="{ name: 'settings-tax-rate-group-edit', params: { id: taxRateGroup.id }}">
          <div>
            <div class="level is-mobile component-level-margin">
              <div class="level-left">
                <div class="level-item">
                  <h4 class="text">{{ taxRateGroup.name }}</h4>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <div class="tag is-light">{{ (taxRateGroup.rate * 100).toFixed(2) }}%</div>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <hr>
    </div>
    <div v-else>
      <b-message type="is-danger">
        <strong>No custom tax rate groups have been added.</strong>
      </b-message>
      <hr>
    </div>
    <div class="level">
      <div class="level-left"></div>
      <div class="level-right">
        <div class="level-item">
          <router-link :to="{ name: 'settings-tax-rate-group-create' }" class="button is-primary is-fullwidth-mobile">Add Tax Rate Group</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

@import '../../scss/_theme-default.scss';

.card a {
  color: $dark;
}

.card a:hover {
  color: lighten($dark, 20);
}

.card {
  background-color: #F9F9F9;
}

.subtext {
  color: lighten($dark, 40);
}

</style>

<script>

import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  name: 'TaxRateGroups',
  data () {
    return {
      breadcrumbs: [
        { name: 'Settings', route: 'settings-home' },
        { name: 'Tax Rate Groups', route: null }
      ]
    }
  },
  computed: {
    taxRateGroups () {
      return this.$store.getters['settings/taxRateGroups']
    }
  },
  mounted () {
    this.$store.dispatch('settings/fetchTaxRateGroups')
      .then(() => {})
      .catch((err) => {
        this.$buefy.toast.open({
          message: `Error: ${err.message}`,
          type: 'is-danger',
          duration: 4000,
          queue: false
        })
      })
  },
  components: { Breadcrumbs }
}
</script>
